<template>
  <div class="w-full flex flex-col md:flex-row items-center justify-center min-h-96">
    <!-- md:justify-evenly -->
    <!-- <div>ICON</div> -->
    <Alert
      customClasses="max-w-550"
      type="fail"
      :title="content.fail"
      :message="content.paymentFail"
    >
      <router-link
        to="/"
        class="alert-btn text-text-white text-white lora-bold whitespace-nowrap bg-secondaryColor duration-300 mt-6"
      >
        {{ content.goHome }}
      </router-link>
    </Alert>
  </div>
</template>

<script>
export default {
  inject: ["content"],
};
</script>
